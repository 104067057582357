import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ProjectCard = ({ link, name, description, githubUrl, thumbnail, toolsUsed }) => (
    <div className="proj-wrapper border border-gray rounded py-4 h-100">
        {thumbnail && (
            <div className="col-4 col-lg-3">
                <img alt="project.title" src={thumbnail} />
            </div>
        )}
        <div className={`d-inline-flex flex-column justify-content-between h-100 ${thumbnail ? 'col-8 col-lg-9' : 'col-12'}`}>
        <div className="w-100">
            {link.includes('https://') ? (
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <h3>{name}</h3>
                    </a>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <h3><span className='fa fa-external-link' /></h3>
                    </a>
                </div>

            ) : (
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <Link to={link}>
                        <h3>{name}</h3>
                    </Link>
                    <Link to={link}>
                        <h3><span className='fa fa-external-link' /></h3>
                    </Link>
                </div>
            )}

            <p>{description}</p>
        </div>
        {githubUrl && (
            <div className="text-right w-100">
                <a href={githubUrl} className="text-secondary" target="_blank" rel="noopener noreferrer"><span className="fa fa-github text-lg"></span>&nbsp;&nbsp;See Source on GitHub</a>
            </div>
        )}
        {toolsUsed && toolsUsed.map((tool) =>   (
            <div className={tool} >
            </div>
        ))}
      </div>
    </div>
);

export default ProjectCard;