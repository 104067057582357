import React from 'react';

import './Contact.scss';

const Contact = () => (
    <div className="contact-wrap">
        <h1 className="text-center">Find Me On:</h1>
        <div className="row pt-4">
            <div className="col-12 col-sm-6 text-center py-2">
                <a href="https://github.com/rcwillett" target="_blank" rel="noopener noreferrer" className="media-icon fa fa-github"><span className="sr-only">Github</span></a>
            </div>
            <div className="col-12 col-sm-6 text-center py-2">
                <a href="https://www.linkedin.com/in/ross-willett/" target="_blank" rel="noopener noreferrer" className="media-icon fa fa-linkedin"><span className="sr-only">Linkedin</span></a>
            </div>
        </div>
    </div>
);

export default Contact;