import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import {
  Contact,
  // Home,
  PageNotFound,
  Projects,
} from './pages';

import {
  DefaultTemplate
} from './templates';

import './styles/styles.scss';
import { FraudEmailIdentifier } from './components';
// import AustinPhraseGenerator from './components/AustinPhraseGenerator';

function App() {
  return (
    <BrowserRouter className="App">
      <Switch>
        <Route exact path="/">
          <DefaultTemplate>
            <Projects />
          </ DefaultTemplate>
        </Route>
        <Route exact path="/projects">
          <DefaultTemplate>
            <Projects />
          </ DefaultTemplate>
        </Route>
        <Route exact path="/contact">
          <DefaultTemplate>
            <Contact />
          </ DefaultTemplate>
        </Route>
        <Route exact path="/emailIdentifier">
          <DefaultTemplate>
            <FraudEmailIdentifier />
          </DefaultTemplate>
        </Route>
        {/* <Route exact path="/oh-behave">
          <DefaultTemplate>
            <AustinPhraseGenerator />
          </ DefaultTemplate>
        </Route> */}
        <Route path="*">
          <DefaultTemplate>
            <PageNotFound />
          </ DefaultTemplate>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
