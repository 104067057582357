import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';
import { useState } from "react";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import axios from 'axios';

import Loader from '../Loader';

const FraudEmailIdentifier = () => {
    const [componentState, setComponentState] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [percentFraud, setPercentFraud] = useState(0);
    const [isFraud, setIsFraud] = useState(false);

    const reset = () => {
        setEmailInput('');
        setComponentState(0);
        setError('');
        setPercentFraud(0);
        setIsFraud(false);
    }

    const emailInputChange = (e) => {
        setEmailInput(e.target.value);
    }

    const submitEmailContent = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const form = new FormData(e.target);
            const content = form.get('content');
            if (content.trim() !== '') {
                const response = await axios.post('https://fraudemailidentifier.azurewebsites.net/identifyFraudEmail/', { content });
                setIsFraud(response.data.is_fraud === 'True');
                setPercentFraud((parseFloat(response.data.chance_fraud) * 100).toFixed(2));
                setComponentState(1);
            } else {
                setError('You must add content to be verified');
            }
        } catch (error) {
            console.log(error);
            setError('An unexpected error occurred');
        }
        setLoading(false);
    }

    const addPreset = (e) => {
        e.preventDefault();
        setEmailInput(`H͏͏e͏͏l͏͏l͏͏o͏͏,͏͏

W͏͏e͏͏ ͏͏h͏͏a͏͏v͏͏e͏͏ ͏͏t͏͏e͏͏m͏͏p͏͏o͏͏r͏͏a͏͏r͏͏i͏͏l͏͏y͏͏ ͏͏p͏͏l͏͏a͏͏c͏͏e͏͏d͏͏ ͏͏y͏͏o͏͏u͏͏r͏͏ ͏͏A͏͏m͏͏a͏͏z͏͏o͏͏n͏͏ ͏͏a͏͏c͏͏c͏͏o͏͏u͏͏n͏͏t͏͏ ͏͏o͏͏n͏͏ ͏͏h͏͏o͏͏l͏͏d͏͏ ͏͏a͏͏n͏͏d͏͏ ͏͏c͏͏a͏͏n͏͏c͏͏e͏͏l͏͏e͏͏d͏͏ ͏͏a͏͏n͏͏y͏͏ ͏͏p͏͏e͏͏n͏͏d͏͏i͏͏n͏͏g͏͏ ͏͏o͏͏r͏͏d͏͏e͏͏r͏͏s͏͏ ͏͏o͏͏r͏͏ ͏͏s͏͏u͏͏b͏͏s͏͏c͏͏r͏͏i͏͏p͏͏t͏͏i͏͏o͏͏n͏͏s͏͏ ͏͏b͏͏e͏͏c͏͏a͏͏u͏͏s͏͏e͏͏ ͏͏w͏͏e͏͏ ͏͏d͏͏e͏͏t͏͏e͏͏c͏͏t͏͏e͏͏d͏͏ ͏͏u͏͏n͏͏u͏͏s͏͏u͏͏a͏͏l͏͏ ͏͏a͏͏c͏͏t͏͏i͏͏v͏͏i͏͏t͏͏y͏͏ ͏͏o͏͏n͏͏ ͏͏i͏͏t͏͏.͏͏

T͏͏o͏͏ ͏͏r͏͏e͏͏s͏͏t͏͏o͏͏r͏͏e͏͏ ͏͏y͏͏o͏͏u͏͏r͏͏ ͏͏a͏͏c͏͏c͏͏o͏͏u͏͏n͏͏t͏͏,͏͏ ͏͏y͏͏o͏͏u͏͏ ͏͏c͏͏a͏͏n͏͏ ͏͏c͏͏l͏͏i͏͏c͏͏k͏͏ ͏͏t͏͏h͏͏e͏͏ ͏͏b͏͏u͏͏t͏͏t͏͏o͏͏n͏͏ ͏͏b͏͏e͏͏l͏͏o͏͏w͏͏ ͏͏a͏͏n͏͏d͏͏ ͏͏f͏͏o͏͏l͏͏l͏͏o͏͏w͏͏ ͏͏o͏͏n͏͏-͏͏s͏͏c͏͏r͏͏e͏͏e͏͏n͏͏ ͏͏i͏͏n͏͏s͏͏t͏͏r͏͏u͏͏c͏͏t͏͏i͏͏o͏͏n͏͏s͏͏.͏͏ ͏͏O͏͏n͏͏c͏͏e͏͏ ͏͏y͏͏o͏͏u͏͏ ͏͏h͏͏a͏͏v͏͏e͏͏ ͏͏p͏͏r͏͏o͏͏v͏͏i͏͏d͏͏e͏͏d͏͏ ͏͏t͏͏h͏͏e͏͏ ͏͏r͏͏e͏͏q͏͏u͏͏i͏͏r͏͏e͏͏d͏͏ ͏͏i͏͏n͏͏f͏͏o͏͏r͏͏m͏͏a͏͏t͏͏i͏͏o͏͏n͏͏,͏͏ ͏͏w͏͏e͏͏ ͏͏w͏͏i͏͏l͏͏l͏͏ ͏͏r͏͏e͏͏v͏͏i͏͏e͏͏w͏͏ ͏͏i͏͏t͏͏ ͏͏a͏͏n͏͏d͏͏ ͏͏r͏͏e͏͏s͏͏p͏͏o͏͏n͏͏d͏͏ ͏͏w͏͏i͏͏t͏͏h͏͏i͏͏n͏͏ ͏͏2͏͏4͏͏ ͏͏h͏͏o͏͏u͏͏r͏͏s͏͏.͏͏

S͏͏i͏͏n͏͏c͏͏e͏͏r͏͏e͏͏l͏͏y͏͏,͏͏

C͏͏u͏͏s͏͏t͏͏o͏͏m͏͏e͏͏r͏͏ ͏͏S͏͏e͏͏r͏͏v͏͏i͏͏c͏͏e͏͏
A͏͏m͏͏a͏͏z͏͏o͏͏n͏͏.͏͏c͏͏o͏͏m͏

S͏͏i͏͏g͏͏n͏͏-͏͏i͏͏n͏͏ ͏͏t͏͏o͏͏ ͏͏A͏͏m͏͏a͏͏z͏͏o͏͏n͏͏
        `)
    }

    if (componentState === 0) {
        return (
            <div className="row py-3">
                <div className="col-12 col-lg-8 offset-lg-2">
                    {loading ? (
                        <div className="col-12 pb-3 text-center">
                            <Loader size={48} />
                        </div>
                    ) : (
                        <div className="row text-center">
                            <div className="col-12 py-3">
                                <h1 className="pt-lg-4 text-primary mb-0">Fraud Email Identifier</h1>
                            </div>
                            <div className="col-12 pb-3">
                                Enter the text content from an email below and click the "Identify" button to retrieve a prediction if the email is fraudulent or not!
                            </div>
                            <form className="col-12" onSubmit={submitEmailContent}>
                                <div className="w-100 text-center">
                                    <textarea
                                        className="form-control w-100"
                                        name='content'
                                        rows="8"
                                        placeholder="Enter email text content"
                                        value={emailInput}
                                        onChange={emailInputChange}
                                    />
                                {error && (
                                    <div className="w-100 pt-1">
                                        <div className="text-danger text-center">{error}</div>
                                    </div>
                                )}
                                </div>
                                <div className="py-3 w-100 d-flex justify-content-between">
                                    <button className="btn btn-secondary" onClick={addPreset}>
                                        Use Preset
                                    </button>
                                    <button type='submit' className="btn btn-primary">
                                        Identify
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    if (componentState === 1) {
        return (
            <div className="row py-3">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="row">
                        <div className="col-12 pb-1 text-center pb-3">
                            <h1>Certainty of Fraud</h1>
                        </div>
                        <div className="col-12 pb-1 text-center pb-3">
                            <div className="percent-container">
                                <CircularProgressbar
                                    strokeWidth={10}
                                    value={percentFraud}
                                    text={`${percentFraud}%`}
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: isFraud ? '#dc3545' : '#28a745',
                                        textColor: isFraud ? '#dc3545' : '#28a745',
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-12 pb-1 text-center pb-3">
                            {isFraud ? (
                                <h3 className='text-danger'>Email is likely fraud</h3>
                            ) : (
                                <h3 className='text-success'>Email is probably not fraud</h3>
                            )}
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={reset}>
                                Check Another Email
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 pb-3">
                <h1>Unexpected Error!</h1>
            </div>
            <div className="col-12 text-center">
                <button className="btn btn-primary" onClick={reset}>
                    Reset
                </button>
            </div>
        </div>
    )

    
}

export { FraudEmailIdentifier }