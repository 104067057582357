import React from 'react';
import { NavLink } from 'react-router-dom';

import CirclePic from '../assets/img/profile_pic_circle_no_bg_320x320.png';

const LINKS = [
    // {
    //     name: 'Home',
    //     iconclassName: 'fa-home',
    //     url: '/'
    // },
    {
        name: 'Projects',
        iconclassName: 'fa-wrench',
        url: '/projects'
    },
    {
        name: 'Connect',
        iconclassName: 'fa-envelope',
        url: '/contact'
    }
];

const DefaultTemplate = ({ children }) => (
    <div>
        <nav className="wltt-nav navbar navbar-expand-lg navbar-dark pt-3 pb-3">
            <div id="Navbar" className="container">
                <div className="align-items-center d-none d-lg-inline-flex">
                    <NavLink className="navbar-brand" to="/">
                        <img className="wt-main-nav-logo wt-nav-logo" src={CirclePic} alt="Welcome to Willitt.ca" />
                    </NavLink>
                    <div className="d-inline-flex flex-column justify-content-end">
                        <div className="d-inline h3 mb-1 text-primary">Ross Willett</div>
                        <div className="d-inline h5 mb-0">Software Developer</div>
                    </div>
                </div>
                <ul className="navbar-nav justify-content-between align-content-center">
                    {LINKS.map(link => (
                        <li className= "nav-item">
                            <NavLink className="nav-link" to={link.url} activeClassName="active" exact>
                                <span className={`nav-symbol fa ${link.iconclassName}`} sr-hidden="true" role="img" ></span>{link.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
        <main role="main" className="container full-body-container pt-lg-2">
            <div className="top-brand row mb-4 d-lg-none align-items-center border-bottom border-white pb-4">
                <div className="col-4 col-md-3 pr-2 pr-sm-4">
                    <img className="wt-nav-logo img-responsive mw-100" src={CirclePic} alt="Welcome to rosswillett.ca" />
                </div>
                <div className="col-8 col-md-9 d-none d-sm-block pl-0">
                    <h1 className="text-primary">Ross Willett</h1>
                    <div className="d-inline h5 mb-0">Software Developer</div>
                </div>
                <div className="col-8 pl-0">
                    <h1 className="text-primary d-sm-none">rosswillett.ca</h1>
                </div>
            </div>
            <div id="outlet-container">
                <div className="outlet-body">
                    {children}
                </div>
            </div>
        </main>
    </div>
);

export default DefaultTemplate;