import React from 'react';

import HalImg from '../../assets/img/hal_web.png';

import './PageNotFound.scss';

const PageNotFound = () => (
    <div className="d-flex flex-column">
        <h1 className="text-center pt-md-5">ERROR 404: I'm Sorry Dave, I'm afraid I can't do that.</h1>
        <div className="d-inline-block mx-auto py-3">
            <img src={HalImg} alt="hal" />
        </div>
        <h1 className="text-center"><a href="/">Open the Pod Bay Doors</a></h1>
    </div>
);

export default PageNotFound;