export const PROJECTS = [
    {
        name: 'Fraud Email Identifier',
        description: 'A web app with a dockerized Python back end which uses a neural network based on the BERT transformer model to analyze email text and return a percent confidence indicating if the email is fraudulent.',
        link: '/emailIdentifier',
        thumbnail: '',
        githubUrl: 'https://github.com/rcwillett/email_fraud_detector',
        toolsUsed: ['Python', 'GIT', 'DOCKER']
    },
    {
        name: 'K-Mon',
        description: 'A project produced as a part of the PromptHacks 2023 event run by Voiceflow. Our project placed 4th out of 63 teams. This project uses the Voiceflow app, prompt hacking and some javascript code to produce an educational and fun choose your own adventure chat bot for Grade 4 students.',
        link: 'https://devpost.com/software/k-mon',
        thumbnail: '',
        githubUrl: '',
        toolsUsed: ['JavaScript', 'PromptHacking', 'Voiceflow']
    },
    {
        name: 'ChatAXP',
        description: 'A project produced as part of a 24 hour hackathon run by BrainStation in partnership with American Express. For this project, I created a web scraper to extract commonly asked questions and their associated answers from the American Express website. I used this data to create a simple vector DB, and set up a server to facilitate our chat application functionality using the vector DB, and OpenAIs SDK.',
        link: 'https://github.com/rcwillett/industry-project-amex-server',
        thumbnail: '',
        githubUrl: 'https://github.com/rcwillett/industry-project-amex-server',
        toolsUsed: ['Python', 'JavaScript', 'GIT', 'Node.js']
    },
    {
        name: 'The Motion App',
        description: 'The web app that allows marketers to better visualize and measure performance of their ad campaigns and creatives. The front end is built using React, Material UI, D3.js and GraphQL. The back end is a dockerized app built on Node with Express, and uses MongoDB.',
        link: 'https://projects.motionapp.com',
        thumbnail: '',
        githubUrl: '',
        toolsUsed: ['React', 'NODE', 'GIT', 'DOCKER']
    },
    {
        name: 'Shoelace App',
        description: 'The web app provided to shoelace users to visualize, edit and view performance of their ad campaigns. The front end is built using React, bootstrap, scss and GraphQL. The back end is built on Node.js and Express.js, and uses MongoDB, SQL and Azure Table Storage.',
        link: 'https://my.shoelace.com',
        thumbnail: '',
        githubUrl: '',
        toolsUsed: ['React', 'NODE', 'GIT',]
    },
];