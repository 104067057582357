import React from 'react';

import { PROJECTS } from '../../constants';

import ProjectCard from './ProjectCard';
import './Projects.scss';

const Projects = () => (
    <div>
        <h1 className="pt-lg-4">Projects</h1>
        <p>Below is a list of some of the more recent projects I've worked on. More of my work can be found on <a href="https://github.com/rcwillett" target="_blank" rel="noopener noreferrer">GitHub</a>.</p>
        <div className="row">
            {PROJECTS.map(project => (
                <div className="col-12 col-md-6 my-4">
                    <ProjectCard {...project}></ProjectCard>
                </div>
            ))}
        </div>
    </div>
);

export default Projects;